







































































import { AssetMapping } from "@/utils/types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop({ required: true }) tokens!: string[];
  @Prop({ required: true }) debt!: { [key: string]: string };

  private activeSection = 0;
  private sectionsCount = 0;

  private repayAmounts: AssetMapping<string> = {
    DAI: "0",
    USDC: "0",
    USDT: "0",
    "3CRV": "0"
  };

  private enoughAllowanceFor: AssetMapping<boolean> = {
    DAI: false,
    USDC: false,
    USDT: false,
    "3CRV": false
  };

  private pendingDeposit: AssetMapping<boolean> = {
    DAI: false,
    USDC: false,
    USDT: false,
    "3CRV": false
  };

  mounted() {
    const sections = this.$el.querySelectorAll(".form-section");
    this.sectionsCount = sections.length;
    sections[0].classList.add("active");
  }

  async next() {
    const formSections = this.$el.querySelectorAll(".form-section");

    this.activeSection++;

    let active = false;
    for (const formSection of formSections) {
      if (active) {
        formSection.classList.add("active");
        break;
      }
      if (formSection.classList.contains("active")) {
        active = true;
        formSection.classList.remove("active");
      }
    }
  }

  async back() {
    const formSections = Array.from(
      this.$el.querySelectorAll(".form-section")
    ).reverse();

    this.activeSection--;

    let active = false;
    for (const formSection of formSections) {
      if (active) {
        formSection.classList.add("active");
        break;
      }
      if (formSection.classList.contains("active")) {
        active = true;
        formSection.classList.remove("active");
      }
    }
  }

  async repay() {
    console.log("Repaying");
  }
}
