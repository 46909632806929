























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import PageHeader from "@/components/PageHeader.vue";
import PositionActionEntry from "@/components/position/PositionActionEntry.vue";
import { Risk, tokenDecimals } from "@/utils/constants";
import {
  formatBigNumberString,
  formatRay,
  formatRayPercentage
} from "@/utils/helpers";
import { AssetSymbol, Contracts } from "@/utils/types";
import { ethers } from "ethers";
import { AsyncComputed } from "@/utils/AsyncComputed";
import { Position } from "@/utils/Position";
import { Getter, State } from "vuex-class";
import { IInterestModel } from "@/contracts/IInterestModel";
import { ILeveragePool } from "@/contracts/ILeveragePool";
import IInterestModelABI from "@/contracts/IInterestModel.json";
import ILeveragePoolABI from "@/contracts/ILeveragePool.json";
import AccountButton from "@/components/AccountButton.vue";
import RepayPositionModalContent from "@/components/position/RepayPositionModalContent.vue";

@Component({
  components: {
    PageHeader,
    PositionActionEntry,
    AccountButton,
    RepayPositionModalContent
  }
})
export default class extends Vue {
  @Prop() position!: Position;

  @State contracts!: Contracts;
  @State provider!: ethers.providers.Provider;

  @Getter isConnected!: boolean;

  get poolName() {
    if (this.position.asset.toLowerCase() === "3crv") {
      return "Curve 3Pool";
    }

    return "-";
  }

  get tokens() {
    return ["DAI", "USDC", "USDT"];
  }

  @AsyncComputed({ default: "-" })
  async formattedLeverageMultipler() {
    const leverageMultipler = await this.position.leverageMultiplier(
      this.contracts,
      this.provider
    );

    if (leverageMultipler.eq(0)) {
      return "Infinite";
    }

    return formatRay(leverageMultipler, 4);
  }

  // TODO: Get
  get formattedMaxLeverageMultiplier() {
    return "100.00";
  }

  @AsyncComputed()
  async safety() {
    return await this.position.riskLevel(this.contracts, this.provider);
  }

  get formattedSafety() {
    if (this.position.inTolling) {
      return "tolled";
    }

    if (this.safety == undefined) {
      return "";
    }

    switch ((this.safety as unknown) as Risk) {
      case Risk.LIQUIDATABLE:
        return "liquidatable";
      case Risk.TOLLABLE:
        return "tollable";
      case Risk.AT_RISK:
        return "at risk";
      case Risk.SAFE:
        return "safe";
      default:
        console.error(this.safety, "no handled");
        throw new Error("Unhandled case");
    }
  }

  @AsyncComputed()
  async leverageUtilization() {
    return formatRayPercentage(
      await this.position.leverageUtilization(this.contracts, this.provider),
      4
    );
  }

  @AsyncComputed({ default: "-" })
  async positionValueInUsd() {
    const value = await this.position.valueInUsdAsRay();
    return formatRay(value, 2);
  }

  @AsyncComputed({ default: "-" })
  async positionUnderlyingApy() {
    const apy = await this.position.underlyingApy();
    return formatRayPercentage(apy, 4);
  }

  @AsyncComputed({ default: "-" })
  async debtValueInUsd() {
    const debtValue = await this.position.debtValueInUsdAsRay(
      this.contracts,
      this.provider
    );
    return formatRay(debtValue, 2);
  }

  @AsyncComputed({ default: "-" })
  async debtApr() {
    const avgDebtApr = await this.position.avgDebtApr(
      this.contracts,
      this.provider
    );

    return formatRayPercentage(avgDebtApr, 4);
  }

  @AsyncComputed({ default: "-" })
  async netValueInUsd() {
    const value = await this.position.valueInUsdAsRay();
    const debtValue = await this.position.debtValueInUsdAsRay(
      this.contracts,
      this.provider
    );

    return formatRay(value.sub(debtValue), 2);
  }

  @AsyncComputed({ default: "-" })
  async positionEffectiveApy() {
    const apy = await this.position.effectiveApy(this.contracts, this.provider);

    return formatRayPercentage(apy, 4);
  }

  @AsyncComputed({
    default: {
      DAI: "-",
      USDC: "-",
      USDT: "-"
    }
  })
  async debtAmounts() {
    const debt = await this.position.debtInAsset(this.contracts, this.provider);
    for (const token of ["DAI", "USDC", "USDT"]) {
      if (debt[token] === undefined) {
        debt[token] = "0";
      }
    }

    return debt;
  }

  get debtAmountsInUsd() {
    // TODO: Do conversion
    return this.debtAmounts;
  }

  @AsyncComputed({
    default: {
      DAI: "-",
      USDC: "-",
      USDT: "-"
    }
  })
  async debtAprs() {
    const leveragePoolAddress = this.contracts.LeveragePool;

    const leveragePool = new ethers.Contract(
      leveragePoolAddress,
      ILeveragePoolABI,
      this.provider
    ) as ILeveragePool;

    let rates: { [key: string]: any } = {};
    // TODO: Unhardcode tokens
    for (const token of ["DAI", "USDC", "USDT"]) {
      // NOTE: Assumes 1 reserve for each token
      const reserveAddress = (
        await leveragePool.getReserveForToken(
          this.contracts[token as AssetSymbol]
        )
      )[0];
      const reserve = await leveragePool.getReserveData(reserveAddress);

      const totalDebt = reserve.cached.totalDebt;
      const totalSaving = reserve.cached.totalSaving;
      const interestRateModelAddress = reserve.interestModel;

      const interestRateModel = new ethers.Contract(
        interestRateModelAddress,
        IInterestModelABI,
        this.provider
      ) as IInterestModel;

      const underlyingAssetAddress = this.contracts[token as AssetSymbol];
      const [, borrowRate] = await interestRateModel.getInterestRates(
        underlyingAssetAddress,
        totalDebt,
        totalSaving
      );

      const ray = ethers.BigNumber.from(10).pow(27);

      const percentage = borrowRate.mul(100).div(ray);
      const rest = borrowRate.mul(100).mod(ray);
      let formattedRest = "";
      for (let index = 0; index < 27 - rest.toString().length; index++) {
        formattedRest += "0";
      }
      formattedRest += rest.toString();
      formattedRest = formattedRest.slice(0, 2);

      rates[token] = `${percentage.toString()}.${formattedRest}`;
    }

    return rates;
  }
}
