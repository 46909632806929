























import { Component, Prop, Vue } from "vue-property-decorator";
import ActionModal from "@/components/ActionModal.vue";
import { component } from "vue/types/umd";

@Component({
  components: { ActionModal }
})
export default class extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) desc!: string;
  @Prop() icon!: string;
  @Prop({ default: "350" }) height!: string;
  @Prop({ default: "500" }) width!: string;

  private modalId =
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9);
}
